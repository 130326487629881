<template>
    <div class="container pb-5">
        <!-- Nav com breadcrumb -->
        <div class="nav-rotas-anteriores pt-5 px-3">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/categorias">Categorias</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{$route.params.curso}}</li>
                </ol>
            </nav>
        </div>  

        <hr>
        <!-- {{$route.params}}
        <div id="nav">
            <router-link :to="'/curso=' + $route.params.curso">Home</router-link> |
            <router-link :to="'/curso=' + $route.params.curso + '/profile'">profile</router-link> | 
            <router-link :to="'/curso=' + $route.params.curso + '/posts'">post</router-link>
        </div> -->

        <!-- Cabeçalho da Categoria -->
        <div class="row header-categoria py-5 px-3">

            <!-- Devtool para mudar de curso rápido -->
            <!-- <div class="row">
                <b-form-select  v-model="curso_escolhido" :options="options_cursos" @change="retornaLivrosFirebasePorCurso(curso_escolhido, num_startAt, num_limit)"></b-form-select>
            </div> -->

            <div class="col-3 col-md-1 categoria-icon">
                <img :src="'https://www.perlego.com/images/subject_icons/' + filtra_categoria[0].icon" width="50">
            </div>
            <div class="col-8 col-md-8 text-start" style="align-self: center">
                <h1 class="categoria-title">{{filtra_categoria[0].name}}</h1>
            </div>
        </div>

        <!-- Lista de estudos, subcategorias, cursos -->
        <!-- <div>
            <b-nav tabs class="mb-3">
                <b-nav-item v-for="sub in sub_menu" :key="sub.id" @click="mudarMenu(sub.path)" :active="sub.state">{{sub.nome}} ({{sub.num}})</b-nav-item>
            </b-nav>
        </div> -->

        <b-nav tabs>
                <b-nav-item :to="'/curso='+ $route.params.curso +'/livros'" exact exact-active-class="active">Livros</b-nav-item>
                <b-nav-item :to="'/curso='+ $route.params.curso +'/subcategorias'" exact exact-active-class="active">Subcategorias</b-nav-item>
                <b-nav-item :to="'/curso='+ $route.params.curso +'/lista-de-leituras'" exact exact-active-class="active">Listas de Leitura</b-nav-item>
                <b-nav-item :to="'/curso='+ $route.params.curso +'/videos'" exact exact-active-class="active">Videos</b-nav-item>
                <b-nav-item :to="'/curso='+ $route.params.curso +'/cursos'" exact exact-active-class="active">Cursos</b-nav-item>
            </b-nav>

        <transition name="route" mode="out-in">
            <router-view name="livros_por_curso_view"></router-view>
        </transition>
        

        <div class="row main-content">
            <div v-if="menu_selecionado === 'menu_livros'">
                <!-- <booksRowGallery :key="key_atualizacao" :targetArray="acervo"></booksRowGallery> -->
            </div>
            <div v-else-if="menu_selecionado === 'menu_sub_cat'">
                <h1>Sub categorias</h1>
            </div>
            <div v-else-if="menu_selecionado === 'menu_lista_leitura'">
                <h1>Listas de leituras</h1>
            </div>
            <div v-else-if="menu_selecionado === 'menu_cursos'">
                <h1>Menu de cursos</h1>
            </div>
        </div>

        

    </div>
</template>

<script>
import tags_lista from '@/store/categorias.js'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: {
    },
    data() {
        return {
            menu_selecionado: "menu_livros",
            curso_escolhido: "",
            sub_menu: [
                {nome: 'Livros', num: 0 , state:true, path: 'menu_livros'},
                {nome: 'Sub-categorias', num:'24', state:false, path: 'menu_sub_cat'},
                {nome: 'Listas de Leituras', num:'10', state:false, path: 'menu_lista_leitura'},
                {nome: 'Cursos', num:'2', state:false, path: 'menu_cursos'},
                {nome: 'Vídeos', num:'2', state:false, path: 'menu_vídeos'},
            ],
            num_startAt: 0,
            num_limit: 100,
            first_book: 0,
            last_book: 0,
            previous_start_points: [],
            acervo: [],
            key_atualizacao: 0,
            options_cursos: [],
        }
    },
    computed: {
        // Aqui filtramos todo o array de Tags para encontrar o icone e o nome categoria da página
        filtra_categoria: function(){
            return tags_lista.lista.filter(x => x.path === this.$route.params.curso)
        },
        historicoNavIsFalse: function(){
            // console.log('btn', this.previous_start_points.length > 0)
            // console.log('start atual: ', this.first_book)
            // console.log('primeiro start point', this.previous_start_points[0])
            // O btn de voltar ficará desativado sempre que estiver na primeira página de resultados. Seja por ainda não
            //ter saido da primiera ou por ter voltado a primeira página.
            return this.previous_start_points.length === 0 || this.first_book === this.previous_start_points[0]
        }
    },
    methods: {

        retornaContadorPorCurso(curso) {
            db.collection("contadores").doc(curso)
            .get()
            .then((doc) => {
                console.log('Contador de: ', curso, doc.data().livros)
                this.sub_menu[0].num = doc.data().livros.length
            })
            .catch((error) => {
                console.log(error);
            });
        },

        mudaCursoOnStage(selected){
            this.curso_escolhido = selected
        },

        mudarMenu(menuItem) {
            menuItem = menuItem.toString()
            this.menu_selecionado = menuItem
            this.sub_menu = this.sub_menu.map(x => {
                x.path == menuItem ? x.state = true : x.state = false
            })
        }
    
        // retornaLivroFirebasePormmsid: function(mms_id){
        //     db.collection("acervo-completo").where("mms_id", "==", mms_id)
        //     .get()
        //     .then((doc) => {
        //         if (doc.exists) {
        //             return doc.data()
        //         } else {
        //             // doc.data() will be undefined in this case
        //             console.log("O documento socilicitado pelo mms_id: ", mms_id, " não existe");
        //         }
        //     })
        //     .catch((error) => {
        //         console.log("Não foi possivel retornar o livro solicitados para o mms_id: ", mms_id, ". Erro: ", error);
        //     });
    },
    mounted(){
        
        this.curso_escolhido = this.$route.params.curso

        // GAMBIARRA DE NORMATIZAÇÃO
        if (this.curso_escolhido === 'medicina' || this.curso_escolhido === "Medicina"){
            this.curso_escolhido = 'MB Medicina'
        } else if (this.curso_escolhido === 'direito' || this.curso_escolhido === "Direito") {
            this.curso_escolhido = 'MB Jurídica'
        } else if (this.curso_escolhido === 'pedagogia' || this.curso_escolhido === "Pedagogia") {
            this.curso_escolhido = 'MB Pedagógica'
        }

        // console.log('1: ', this.curso_escolhido)
        // console.log('2: ', this.retornaContadorPorCurso(this.curso_escolhido))
        // this.sub_menu[0].num = this.retornaContadorPorCurso(this.curso_escolhido)
        this.retornaContadorPorCurso(this.curso_escolhido)
        console.log('3: ', this.sub_menu[0].num)

        this.options_cursos = tags_lista.lista.map((x) =>{
            return {value: x.path, text: x.name}
        })
    },
}

</script>

<style scoped>
button, a {
    color: #000;
    font-weight: 600;
    text-decoration: none;
}
.breadcrumb{
    background-color: transparent;
    justify-content: left;
}
.categoria-title {
    margin: 0px !important;
}
div > .categoria-title {
    align-self: center !important;
}
.pagination {
    justify-content: center;
}
.livro {
	transition: all .3s ease-in-out;
	padding: 10px 0;
}	
.livro:hover {
	transition: all .3s ease-in-out;
	-ms-transform: scale(1.4);
	-webkit-transform: scale(1.4);  
	transform: scale(1.1);
}
.livro img {  
	border-radius: 7px;
	box-shadow: var(--shadow) 0px 3px 8px;
	width: -webkit-fill-available;
}
.livro img:hover {  
	box-shadow: 0px 1px 5px 2px var(--shadow);
}
.categoria-header {
    align-items: center;
    padding-right: 0px !important;
}
.categoria-img{
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    -o-object-fit: cover;
    /* object-fit: cover; */
    box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
}
.categoria-text{
    text-align: left;
}
.categoria-button{
    text-align: right;
    padding-right: 0px !important;
}
@media screen and (max-width: 500px){
    .categoria-text > h1{
        text-align: left;
        font-size: 1.25rem;
    }
    .categoria-img{
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        -o-object-fit: cover;
        object-fit: cover;
        box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
    }
    .btn{
        font-size: 0.6rem;
    }
}


/* BOOTSTRAP TESTE */
/* Extra Small */
@media screen and (min-width: 0px){
	.test{
		border: #32b336 7px solid !important;
	}
	.livro img{
		height: 120px;
	}
}
		/* Small */
@media screen and (min-width: 576px){
	.test{
		border: #ffa600 7px solid !important;
	}
	.livro img{
		height: 150px;
	}
}

/* Medium*/
@media screen and (min-width: 768px){
	.test{
		border: #41a7ff 7px solid !important;
	}
	.livro img{
		height: 170px;
	}
    .breadcrumb{
        justify-content: center;
    }
}

/* Large*/
@media screen and (min-width: 992px){
	.test{
		border: yellow 7px solid !important;
	}
	.livro img{
		height: 200px;
	}
}

/* Extra large */
@media screen and (min-width: 1200px){
	.test{
		border: #ff0053 7px solid !important;
	}
	.livro img{
		max-height: 200px;
	}
    .breadcrumb{
        justify-content: left;
    }
}

</style>


