<template>
<!-- Nesta página estou testando:
1. Opções de subtemas
2. Lista de leituras
3. Icone de grande área -->

    <div class="container pb-5">
        <!-- Nav com breadcrumb -->
        <div class="nav-rotas-anteriores pt-5 px-3">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/categorias">Categorias</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{filtra_categoria[0].name}}</li>
                </ol>
            </nav>
        </div>

        <!-- Cabeçalho da Categoria -->
        <div class="row header-categoria py-5 px-3">
            <div class="col-3 col-md-1 categoria-icon">
                <img :src="'https://www.perlego.com/images/subject_icons/' + filtra_categoria[0].icon" width="50">
            </div>
            <div class="col-8 col-md-8 text-start" style="align-self: center; text-align: start">
                <h1 class="categoria-title">{{filtra_categoria[0].name}}</h1>
            </div>
        </div>

        <!-- Submenu -->
        <div class="row px-3">
            <button v-for="sub in sub_menus" :key="sub" type="button" class="btn btn-primary m-1">
                {{sub.nome}} <span class="badge bg-secondary">{{sub.quantidade}}</span>
            </button>
        </div>
        <hr>

        <!-- Subcategorias -->
        <div class="row px-3">
            <button v-for="tag in lista_sub_tags" :key="tag" type="button" class="btn btn-outline-secondary m-1">{{tag}}</button>
        </div>

        <!-- Galeria de Livros -->
        <booksRowGallery :loading="loading" :key="key_atualizacao" :targetArray="acervo"></booksRowGallery>

        <!-- Paginação -->
        <div class="row mt-5" style="place-content: center;">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li v-if="this.page_number === '1'" else class="page-item disabled">
                        <a @click="mudarPagina(-1)" class="page-link" href="#">Anterior</a>
                    </li>
                    <li v-else class="page-item">
                        <a @click="mudarPagina(-1)" class="page-link" href="#">Anterior</a>
                    </li>
                    <!-- <li class="page-item active"><a class="page-link" href="#">1</a></li> -->
                    <li class="page-item">
                        <a @click="mudarPagina(1)" class="page-link" href="#">Próxima</a>
                    </li>
                </ul>
            </nav>
        </div>
        
    </div>
</template>

<script>
import booksRowGallery from '@/components/booksRowGallery.vue';
import tags_lista from '@/store/categorias.js'
import router from '../router'
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

function soma(x,y){
    // console.log(x,y)
    x = parseInt(x)
    y = parseInt(y)
    let result = x+ y
    // console.log(result)
    return result
}

export default {
    components: {
        booksRowGallery,
    },//sda
    data() {
        return {
            acervo: null, // Lista de livros. Inicial com valor null, mas depois do mouted recebe os livros do firebase
            cursoRota: 'direito', // armazena a categoria escolida na rota
            per_page: 30,
            page_number: this.$route.params.pagina,
            next_page_number: soma(this.$route.params.pagina,1),
            previous_page_number: soma(this.$route.params.pagina,-1),
            key_atualizacao: 0,
            loading: true,
            sub_menus: [{nome: "Livros", quantidade: 2538}, {nome: "Lista de Leitura", quantidade: 8}, {nome: "Subtemas", quantidade: 22}],
            lista_sub_tags: ['Direito Civil', 'Direito Penal', 'Direito Constitucional', 'Direito Econômico', 'Direito do Trabalho', 'Direito Administrativo', 'Direito do Trabalho', 'Direito Processual', "Ciência Política", "Direito Romano", "Direito Internacional Público", "Ética do Profissional Jurídico",'Direito Tributário']
        }
    },
    created() {
      this.loading = true
    },
    mounted(){
        // console.log(this.cursoRota)
        let dataRaw = [];
        db.collection("/sandbox/sandbox-livros-1/" + this.cursoRota)
        .orderBy("index")
        .startAt(this.page_number * this.per_page)
        .limit(this.per_page)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                dataRaw.push(doc.data())// Nossa variável
            });
            this.acervo = dataRaw
            console.log(this.acervo)
            this.loading = false
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
        
    },
    computed:{
        // Aqui filtramos todo o array de Tags para encontrar o icone e o nome categoria da página
        filtra_categoria: function(){
            return tags_lista.lista.filter(x => x.path === this.cursoRota)
        }
    },
    methods: {
        mudarPagina: function (mais_ou_menos) {
            this.loading = true
        // Recebe o parâmetro se é próximo ou anterior, de acordo com o resultado adiciona o decresce o valor da página
            if(mais_ou_menos === 1){
                console.log(1);
                // alert('Próxima página')
                router.push({ path: `/curso=${this.cursoRota}/${this.next_page_number}` })

            } else if (mais_ou_menos === -1) {
                // alert('Página anterior')
                router.push({ path: `/curso=${this.cursoRota}/${this.previous_page_number}` })
            }

        // Atualiza as variáveis de página atual e próxima página
        this.page_number = this.$route.params.pagina,
        this.next_page_number = soma(this.$route.params.pagina,1),
        this.previous_page_number = soma(this.$route.params.pagina,-1)

        let dataRaw = [];
            db.collection("/sandbox/sandbox-livros-1/" + this.cursoRota)
            .orderBy("index")
            .startAt(this.page_number * this.per_page)
            .limit(this.per_page)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    dataRaw.push(doc.data())// Nossa variável
                });
                this.acervo = dataRaw
                this.loading = false
                this.key_atualizacao++ // Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie
                
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: #000;
    font-weight: 600;
    text-decoration: none;
}
.breadcrumb{
    background-color: transparent;
}
.categoria-title {
    margin: 0px !important;
}
div > .categoria-title {
    align-self: center !important;
}
.pagination {
    justify-content: center;
}

/* Extra Extra Small */
@media screen and (min-width: 0px){
    .test{
        border: #32b336 7px solid !important;
    }

}

/* Extra Small */
@media screen and (min-width: 350px){
    .test{
        border: #32b336 7px solid !important;
    }
    .breadcrumb{
        justify-content: center;
    }

}

/* Small */
@media screen and (min-width: 576px){
    .test{
        border: #ffa600 7px solid !important;
    }
}

/* Medium*/
@media screen and (min-width: 768px){
    .test{
        border: #41a7ff 7px solid !important;
    }
}

/* Large*/
@media screen and (min-width: 992px){
    .test{
        border: yellow 7px solid !important;
    }
}

/* Extra large */
@media screen and (min-width: 1200px){
    .test{
        border: #ff0053 7px solid !important;
    }
    .breadcrumb{
        justify-content: left;
    }
}
</style>